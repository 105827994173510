<template>
  <div class="liveRoom">
    <div class="liveRoom_conntent">
      <div class="conntentTitle">
        <img class="main_logo" src="@/assets/course/logo.png" alt />
        <div class="titleBox">萨恩课堂</div>
        <div class="titleBox_title">{{ title }}</div>
        <div class="titleBox_appDown">
          <div class="dow-app">
            <span>下载APP</span>
            <img src="@/assets/home/fire.png" alt />
            <div class="dow-box">
              <img :src="website.qrCodeApp" alt />
            </div>
          </div>
        </div>

        <div class="titleBox_userInfo">
          <div class="user_div">
            <img
              class="userimg"
              :src="userInfo.headPic"
              v-if="
                JSON.stringify(userInfo) != '{}' &&
                userInfo != null &&
                userInfo != undefined &&
                userInfo.headPic != '' &&
                userInfo.headPic != null &&
                userInfo.headPic != undefined
              "
            />
            <div class="user_list">
              <p @click="topage('/myself/myCenter')">个人中心</p>
              <p @click="topage('/myself/myClass')">我的课程</p>
              <p @click="topage('/myself/myLive')">我的直播</p>
              <p @click="topage('/myself/myDaYi')">课程答疑</p>
              <p @click="topage('/myself/myAnswer')">我的问答</p>
              <p @click="topage('/myself/myOrder')">我的订单</p>
              <p @click="topage('/myself/editUserInfo')">个人资料</p>
              <p @click="topage('/myself/myInformation')">站内消息</p>
              <p @click="signOut()">退出登录</p>
              <!-- <p @click="topage('/myself/myNote')">课程笔记</p> -->
              <!-- <p @click="topage('/myself/myIntegral')">积分</p> -->
              <!-- <p @click="topage('/myself/myCoupon')">优惠券</p> -->
              <!-- <p @click="topage('/myself/myClassExchange')">课程兑换</p> -->
              <div class="user_list_triangle"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="contentBox">
        <div class="contentBox_left">
          <div class="live_box">
            <div id="div1" class="play_box_other" v-if="liveType == 'ppt' ">
              <div id="ppt" class="play_box_other"></div>
            </div>
            <div class="play_box_other" v-else>
              <div id="player" class="play_box_other"></div>
            </div>

            <!--             
            
             <div class="sliderBox" v-if="state == '2' ">
              <div class="zhiboBottom_box">
                <div class="sliderContent">
                <el-slider 
                class="commonSlider" 
                :show-tooltip="false" 
                v-model="initVideo.currentTime" 
                :max="initVideo.videoLength" 
                @change="changeVideoTime">
                </el-slider>
              </div>
              <div class="sliderContent_kongjian">
                <div class="startOrStop" >
                  <div class="stopBox" v-if="!isShowpues" @click="setPlayerClick(1)"></div>
                  <div class="startBox" v-if="isShowpues" @click="setPlayerClick(2)"></div>
                  <div class="kongjian_box_times">
                    <span>{{bottomTimes}}</span>
                    <span>/</span>
                    <span>{{bottomAllTimes}}</span>
                  </div>
                </div>
                <div class="right_box_bottom">
                  <div class="video_yinliang">
                    <span class="contentSpan">音量</span>
                    <div class="user_list_beisu">
                      <div class="slider_box">
                        <el-slider
                          v-model="yinliangValue"
                          @change="yinliangChange"
                          vertical
                          :min="0"
                          :max="100"
                          height="180px">
                        </el-slider>
                      </div>
                      
                    </div>
                  </div>
                  <div class="video_beishu">
                    <span class="contentSpan">倍速</span>
                    <div class="user_list_beisu">
                      <p @click="setBeiSu(0.5)">
                        <span :class="isactive == 0.5 ? 'isActive' : '' ">0.5X</span>
                      </p>
                      <p @click="setBeiSu(1)">
                        <span :class="isactive == 1 ? 'isActive' : '' ">1X</span>
                      </p>
                      <p @click="setBeiSu(1.25)">
                        <span :class="isactive == 1.25 ? 'isActive' : '' ">1.25X</span>
                      </p>
                      <p @click="setBeiSu(1.5)">
                        <span :class="isactive == 1.5 ? 'isActive' : '' ">1.5X</span>
                      </p>
                      <p @click="setBeiSu(2)">
                        <span :class="isactive == 2 ? 'isActive' : '' ">2X</span>
                      </p>
                    </div>
                  </div>
                  <div class="video_damu" @click="setDanMu">
                    <div v-if="isShowDanMu" class="openDanMu"></div>
                    <div v-else class="closeDanMu"></div>
                    
                    <span>弹幕</span>
                  </div>
                  
                  <div class="videoQp" @click="setLiveFureen">
                    <div></div>
                  </div>

                </div>
                

              </div>

              </div>
              
              
            </div>
            <div v-else class="sliderBox_zhibo" >
              <div class="zhiboBottom_box">
                <div></div>
                <div class="right_box_bottom">
                   <div class="video_yinliang">
                    <span class="contentSpan">音量</span>
                    <div class="user_list_beisu">
                      <div class="slider_box">
                        <el-slider
                          v-model="yinliangValue"
                          @change="yinliangChange"
                          vertical
                          :min="0"
                          :max="100"
                          height="180px">
                        </el-slider>
                      </div>
                      
                    </div>
                  </div>
                  <div class="video_damu" @click="setDanMu">
                    <div v-if="isShowDanMu" class="openDanMu"></div>
                    <div v-else class="closeDanMu"></div>
                    
                    <span>弹幕</span>
                  </div>
                 
                  <div class="videoQp" @click="setLiveFureen">
                    <div></div>
                  </div>

                </div>

              </div>
              

            </div>-->
          </div>
        </div>
        <div class="contentBox_right" v-if="liveType == 'ppt'">
          <div id="div2" @click="teacherClickFn" class="live_box_tearher ssss">
            <div id="player" class="play_box_other"></div>
          </div>
          <div class="chat_room">
            <div id="wrap" class="play_box_other"></div>
          </div>
        </div>
        <div class="contentBox_right" v-else>
          <div id="wrap" class="play_box_other"></div>
        </div>
      </div>
      <div class="bottomBox">
        <div class="iframes-box-ul">
          <ul>
            <li @click="onDrawer(index)" v-for="(item, index) in drawerTitle">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>

    <el-drawer
      :title="drawerTitle[drawerIdx]"
      :visible.sync="drawer"
      direction="rtl"
      class="drawer-bg"
    >
      <div v-if="drawerIdx == 0">
        <ul class="ul">
          <li v-for="(item, index) in dataList" :key="index">
            <img src="@/assets/course/handouts_icon.png" alt />
            <p>{{ item.coursewareName }}</p>
            <img
              class="dow"
              src="@/assets/course/download_icon.png"
              alt
              @click="onFileDown(item.filePath)"
            />
          </li>
        </ul>
      </div>
      <div v-else-if="drawerIdx == 1" class="questions">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入您要提问的内容..."
          v-model="askTextarea"
          maxlength="300"
          resize="none"
          show-word-limit="true"
        ></el-input>
        <fileUpload isList :options="{}" @fileCallback="fileBack"></fileUpload>
        <div class="videoRoom_main_right_content_noteView_btn">
          <!-- <el-button type="info" @click="dayi_cancel()">取消</el-button> -->
          <el-button type="primary" @click="dayi_add()">提交</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { wangzhanpeizhi } from "@/api/login";
import { coursewareList, live_isBuy } from "@/api/livebroadcast";
import fileUpload from "../../components/FileUpload.vue";
import { dayi_add, watchLive, playbackReportedData } from "@/api/other";
import {
  live_playback,
  playbackByChannelId,
  getliveModeByChannelAndType
} from "@/api/course";
import { coursepackage_courseware } from "@/api/other.js";
import { TimeSelect } from "element-ui";
import md5 from "js-md5";
import { post } from "@/utils/ajax.js";
export default {
  name: "liveVideo",
  components: {
    fileUpload
  },
  data() {
    return {
      queryObj: {},
      channelId: 0,
      reportUrl: "",
      yinliangValue: 100,
      videoObj: {},
      userInfo: {},
      website: {},
      liveTitle: "",
      dataList: [],
      drawer: false,
      drawerIdx: 0,
      drawerTitle: [],
      askTextarea: "",
      videoState: "",
      state: "",
      title: "",
      liveType: "ppt",
      isShowDanMu: true,
      isactive: 1,
      initVideo: {
        currentTime: 0,
        videoLength: 1000
      },
      rangeValue: true,
      liveSdk: "",
      timer: null,
      interval: null,
      watchedPlayback: "",
      lvLength: "",
      isShowpues: true,
      bottomTimes: "",
      bottomAllTimes: "",
      watchedLiveing: "",
      stepLength: 1,
      browseTime: 0, // 浏览时长初始值为 0
      clearTimeSet: null,
      plackRateNumber: 1,
      seekLength: 0
    };
  },
  created() {
    //let data =new FormData()
    //data.append('channelId',this.$route.query.channelId);
    this.isShowpues = true;
    this.stepLength = 1;
  },
  mounted() {
    this.channelId = this.$route.query.channelId;
    this.state = this.$route.query.state;
    this.liveTitle = this.$route.query.title;
    this.queryObj = this.$route.query;
    let drawerTitle =
      this.queryObj.liveType == 2 ? ["讲义下载", "我的提问"] : ["讲义下载"];
    this.drawerTitle = drawerTitle;
    document.title = this.liveTitle ? this.liveTitle + "-萨恩课堂" : "萨恩教育";
    this.userInfo = JSON.parse(localStorage.getItem("setuserInfo"));
    window.addEventListener("beforeunload", e => this.beforeunloadHandler(e));
    var obj = {
      channelId: this.$route.query.channelId
    };
    if (this.state == 2) {
      playbackByChannelId(obj).then(res => {
        if (res.data.code == 0) {
          console.log(res.data.data);
          if (res.data.data.contents.length == 0) {
            this.videoState = "";
            this.liveType = "ppt";
            this.title = this.$route.query.title;
          } else {
            this.videoState = res.data.data.contents[0].videoPoolId;
            this.title = res.data.data.contents[0].title;
            this.liveType = res.data.data.contents[0].liveType;
            if (this.state == "2") {
              this.initVideo.currentTime = res.data.data.watchStudyTimeLength;
              this.lvLength = res.data.data.watchStudyTimeLength;
              this.initVideo.videoLength = this.setVideoLength(
                res.data.data.contents[0].duration
              );
              this.watchedPlayback = res.data.data.watchedPlayback;
              this.bottomAllTimes = this.formatSeconds(
                this.initVideo.videoLength
              );
              this.watchedLiveing = res.data.data.watchedLiveing;
              this.seekLength = res.data.data.lastStudyTimeLength;
            }
          }
        }
        this.init();
        this.wangzhanpeizhi();
        this.live_isBuy();
      });
    } else {
      var resultObj = {
        channelId: this.$route.query.channelId,
        liveType: this.$route.query.liveType
      };
      getliveModeByChannelAndType(resultObj).then(res => {
        if (res.data.code == 0) {
          this.title = this.$route.query.title;
          this.liveType = res.data.data == 1 ? "ppt" : "alone";
        } else {
          this.liveType = "ppt";
          this.title = this.$route.query.title;
        }
        this.initVideo.currentTime = 0;
        this.init();
        this.wangzhanpeizhi();
        this.live_isBuy();
      });
    }
  },
  beforeDestroy() {
    //clearInterval(this.timer);
    clearTimeout(this.timer);
    this.timer = null;
    this.liveSdk.destroy(true);
    clearInterval(this.interval);
    clearInterval(this.clearTimeSet);
    window.removeEventListener("beforeunload", this.beforeunloadHandler);
    //this.playbackReportedData_new();
  },
  methods: {
    getCurLength() {
      let times = this.liveSdk.player.currentTime;
      return times;
    },
    beforeunloadHandler(e) {
      if (e) {
        e = e || window.event;
        this.playbackReportedData_new();
        if (e) {
          e.returnValue = "关闭提示";
        }
        return "关闭提示";
      }
    },
    playbackReportedData_new() {
      var obj = {
        coursePackageId: this.$route.query.coursePackageId,
        courseId: this.$route.query.courseId,
        classHourId: this.$route.query.id,
        videoPlan: Math.ceil(this.browseTime),
        videoPlanLength: Math.ceil(this.getCurLength())
      };
      playbackReportedData(obj)
        .then(res => {
          // console.log(res);
        })
        .catch(error => {
          console.log(error);
        });
    },
    setTime() {
      //设置定时器
      this.clearTimeSet = setInterval(() => {
        this.browseTime = this.browseTime + this.plackRateNumber;
      }, 1000);
    },

    yinliangChange(event) {
      let num = event / 100;
      this.liveSdk.player.setVolume(num);
    },
    setBeiSu(val) {
      this.isactive = val;
      this.stepLength = val;
      this.liveSdk.player.setRate(val);
    },
    setDanMu() {
      if (this.isShowDanMu) {
        this.isShowDanMu = false;
        this.liveSdk.player.showBarrage();
      } else {
        this.isShowDanMu = true;
        this.liveSdk.player.hideBarrage();
      }
    },
    // 跳转页面
    topage(page) {
      this.$router.push({
        path: page,
        query: {}
      });
    },

    formatSeconds(value) {
      let res = parseInt(value);
      let h =
        Math.floor(res / 3600) < 10
          ? "0" + Math.floor(res / 3600)
          : Math.floor(res / 3600);
      let m =
        Math.floor((res / 60) % 60) < 10
          ? "0" + Math.floor((res / 60) % 60)
          : Math.floor((res / 60) % 60);
      let s =
        Math.floor(res % 60) < 10
          ? "0" + Math.floor(res % 60)
          : Math.floor(res % 60);

      let str = "";
      str += `${h}:`;
      str += `${m}:`;
      str += s;
      return str;
    },
    setLiveFureen() {
      if (this.liveType == "ppt") {
        this.liveSdk.player.fullScreen.request(
          document.querySelector("#ppt > div")
        );
      } else {
        this.liveSdk.player.fullScreen.request(
          document.querySelector("#player > div")
        );
      }
    },
    videoSwitchFn() {
      var d1 = document.getElementById("div1").innerHTML;
      var d2 = document.getElementById("div2").innerHTML;
      document.getElementById("div2").innerHTML = d1;
      document.getElementById("div1").innerHTML = d2;
      this.liveSdk.player.resize();
    },

    teacherClickFn() {
      this.isShowpues = !this.isShowpues;
    },
    loadPlayerInterval() {
      clearInterval(this.interval);
      var _that = this;
      _that.interval = setInterval(() => {
        _that.playbackReportedData_new();
      }, 10000);
    },

    playbackReportedData(currentTime) {
      var obj = {
        coursePackageId: this.$route.query.coursePackageId,
        courseId: this.$route.query.courseId,
        classHourId: this.$route.query.id,
        videoPlan: Math.ceil(currentTime)
      };
      playbackReportedData(obj)
        .then(res => {
          // console.log(res);
        })
        .catch(error => {
          console.log(error);
        });
    },

    setVideoLength(val) {
      let arys = val.split(":");
      let hours = parseInt(arys[0]) * 60 * 60;
      let fen = parseInt(arys[1]) * 60;
      let miao = parseInt(arys[2]);
      let alllen = hours + fen + miao;
      return alllen;
    },

    getSign(params) {
      var paramsStr = "";
      for (let key in params) {
        paramsStr += key + "" + params[key];
      }
      var appSecret = "76e906eb039e4c05b4c1d707b368d1b6";
      var finalStr = appSecret + paramsStr + appSecret;
      return md5(finalStr).toUpperCase();
    },
    getChatToken() {
      var chatData = {};
      var appId = "fmkz0ciqw1";
      //var channelId="3282616";
      var apiUrl =
        "https://api.polyv.net/live/v3/channel/common/get-chat-token";
      let userinfo = JSON.parse(localStorage.getItem("setuserInfo"));
      var params = {
        appId: appId, // 账号appId
        channelId: this.channelId, // 频道号
        role: "v88083abbf5bfc681ce7d8cdfb32e73f7_8", // 角色,viewer：观看者
        timestamp: new Date().getTime(),
        userId: userinfo.id // 观看者用户Id
      };
      params.sign = this.getSign(params);
      var apiUrls =
        "https://api.polyv.net/live/v3/channel/common/get-chat-token?appId=" +
        appId +
        "&channelId=" +
        params.channelId +
        "&role=" +
        params.role +
        "&timestamp=" +
        params.timestamp +
        "&userId=" +
        params.userId +
        "&sign=" +
        params.sign;
      return new Promise(function(resolve) {
        post(apiUrls, null, function(result) {
          let datas = JSON.parse(result);
          if (datas.code == 200) {
            resolve(datas.data);
          }
        });
      });
    },
    async init() {
      var _that = this;
      var appId = "fmkz0ciqw1";
      //var channelId="3282616";
      var appSecret = "76e906eb039e4c05b4c1d707b368d1b6";
      var channelId = this.channelId;
      let userinfo = JSON.parse(localStorage.getItem("setuserInfo"));
      var userId = userinfo.id;
      var userName = userinfo.nickName || "测试-01";
      var userPic =
        "http://livestatic.videocc.net/assets/wimages/missing_face.png";
      //4.需要先请求以下接口获取token和mediaChannelKey，传给js-sdk
      var chatData = await this.getChatToken(); //从接口获取聊天室token
      //5、新建对象
      this.chatroom = new PolyvChatRoom({
        roomId: channelId,
        userId: userId,
        nick: userName,
        pic: userPic,
        token: chatData.token,
        mediaChannelKey: chatData.mediaChannelKey,
        version: "2.0",
        container: "#wrap",
        width: "100%",
        height: "100%",
        userType: "slice",
        showUserList: false,
        enableAsk: false,
        customChatColor: {
          selfBgColor: "#3e3e4e",
          otherBgColor: "#3e3e4e",
          otherColor: "#fff"
        },

        roomMessage: function(data) {
          // TODO
          // data为聊天室socket消息，当有聊天室消息时会触发此方法
          console.log("chatRoom", data);
          if (data.content) {
            _that.liveSdk.player.sendBarrage(data.content);
          }
        }
      });

      var params = {
        appId: appId,
        channelId: channelId,
        timestamp: new Date().getTime()
      };
      var sign = this.getSign(params);
      //第二步：初始化直播sdk对象
      this.liveSdk = new PolyvLiveSdk({
        channelId: channelId,
        sign: sign, // 频道验证签名
        timestamp: params.timestamp, // 毫秒级时间戳
        appId: appId, // polyv 后台的appId
        socket: this.chatroom.chat.socket,
        user: {
          userId: userId,
          userName: userName,
          pic: userPic
        }
      });

      this.liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
        //第三步：设置直播播放器
        var obj = {};
        if (this.state == "2") {
          obj = {
            type: "vod",
            vid: this.videoState,
            pptEl: "#ppt",
            el: "#player",
            controllerPosition: "ppt",
            showController: true,
            pptNavBottom: "120px",
            autoplay: true,
            vodType: "vod",
            pptPlaceholder: true,
            pptNav: true,
            barrage: true,
            pptWidth: "100%",
            pptHeight: "100%",
            rateList: [0.5, 1, 1.25, 1.5, 1.75, 2]
          };
        } else {
          obj = {
            type: "auto",
            //fileId:this.fileId,
            //url:hFurl,
            //sessionId:this.sessionId,
            pptEl: "#ppt",
            el: "#player",
            controllerPosition: "ppt",
            controller: true,
            pptNavBottom: "80px",
            autoplay: true,
            pptNav: true,
            pptPlaceholder: true,
            barrage: true,
            pptWidth: "100%",
            pptHeight: "100%"
          };
        }
        this.liveSdk.setupPlayer(obj);
        var _that = this;
        this.liveSdk.player.on("loadedmetadata", function() {
          if (_that.state == "2") {
            _that.liveSdk.player.seek(_that.seekLength);
            _that.loadPlayerInterval();
          }
        });
        this.liveSdk.player.on("ratechange", function(event) {
          _that.plackRateNumber = _that.liveSdk.player.playbackRate;
        });
        this.liveSdk.player.on("playing", function() {
          //开始播放
          _that.setTime();
          if (_that.state == "2") {
            _that.loadPlayerInterval();
          }
        });
        this.liveSdk.player.on("pause", function() {
          //暂停

          if (_that.state == "2") {
            clearTimeout(_that.timer);
            clearInterval(_that.clearTimeSet);
          }
        });
      });

      this.liveSdk.on(PolyvLiveSdk.EVENTS.STREAM_UPDATE, function(
        event,
        status
      ) {
        console.log("流状态改变", status);
        _that.liveSdk.reloadPlayer();
      });
    },
    wangzhanpeizhi() {
      wangzhanpeizhi()
        .then(res => {
          if (res.data.code == 0) {
            this.website = res.data.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 父传子
    getTodos(e) {
      this.isUserInfo = e;
    },
    live_isBuy() {
      live_isBuy({ channelId: this.channelId }).then(res => {
        if (res.data.code == 0) {
          let isBuy = res.data.data.isBuy;
          if (isBuy == 1) {
            this.coursewareList();
            this.watchLive();
          } else {
            this.$message.error("您还未购买当前套餐");
            this.$router.replace("/");
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    coursewareList() {
      coursepackage_courseware({ courseId: this.queryObj.courseId }).then(
        res => {
          if (res.data.code == 0) {
            this.dataList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        }
      );
      // coursewareList({ channelId: this.channelId }).then((res) => {
      //   if (res.data.code == 0) {
      //     this.dataList = res.data.data;
      //   } else {
      //     this.$message.error(res.data.msg);
      //   }
      // });
    },
    onFileDown(e) {
      window.open(e, "_blank");
    },
    dayi_add() {
      let fileList = this.fileList;
      let str = "";
      if (fileList != undefined) {
        fileList.forEach((item, index) => {
          str += item.url + ",";
        });
      }
      if (str != "") {
        str = str.substring(0, str.lastIndexOf(","));
      }
      let { id, courseId, coursePackageId } = this.queryObj;
      var obj = {
        classHourId: id,
        courseId: courseId,
        coursePackageId: coursePackageId,
        questionContent: this.askTextarea,
        questionPics: str
      };
      dayi_add(obj)
        .then(res => {
          if (res.data.code == 0) {
            this.askTextarea = "";
            this.fileList = [];
            this.drawerIdx = 0;
            this.drawer = false;
            this.$message.success("提交成功！");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    fileBack(fileList) {
      this.fileList = fileList;
    },
    onDrawer(idx) {
      this.drawer = true;
      this.drawerIdx = idx;
    },
    watchLive() {
      let id = this.queryObj.id;
      watchLive({ liveId: id });
    }
  }
};
</script>

<style lang="scss">
.polyv-chat-room .polyv-msg-main {
  text-align: left;
}
.polyv-chat-room .polyv-user-info {
  text-align: left;
}
.polyv-chat-room .polyv-user-info > span {
  color: #fff;
}
.polyv-chat-room .polyv-chat-list > .polyv-msg {
  color: #ffffff;
}
.polyv-chat-room .polyv-btn-info {
  background: #2d2d2d;
  margin-top: 5px;
}
::-webkit-scrollbar-track {
  background: none;
}
.polyv-cr-head {
  background: #3e3e4e;
}
.polyv-chat-room .polyv-cr-navbar > li.polyv-crn-active {
  color: #fff;
  border-bottom: 3px solid #fff;
}
.polyv-chat-room > .polyv-cr-body {
  background: #2d2d2d;
}
.polyv-chat-room .polyv-chat-input {
  background: #3e3e4e;
}
.polyv-chat-room .polyv-chat-input textarea {
  background: #2d2d2d;
  color: #ffffff;
}
::-webkit-input-placeholder {
  color: #fff;
}
/* IE 10+ */
:-ms-input-placeholder {
  color: #fff;
}
/* Firefox 4-18 */
/* Firefox 19+ */
:-moz-placeholder,
::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.play_box {
  width: 100%;
  height: 100%;
  //padding: 20px 0px 0px 30px;
}

.play_box_other {
  width: 100%;
  height: 100%;
}
.c-watch__menu-panel {
  display: none;
}
.liveRoom {
  width: 100%;
  height: 100vh;
  background: -webkit-linear-gradient(left, #3a387f, #4f7aaf);
}
.liveRoom_conntent {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 80px 0 80px;

  .conntentTitle {
    width: 100%;
    height: 90px;
    position: relative;
    .main_logo {
      width: 35px;
      height: 35px;
      position: absolute;
      left: 0px;
      top: 40px;
      border-radius: 50%;
    }
    .titleBox_appDown {
      position: absolute;
      right: 70px;
      top: 50px;
      font-size: 24px;
      color: #fff;
      letter-spacing: 1px;
    }
    .titleBox {
      position: absolute;
      left: 50px;
      top: 40px;
      font-size: 24px;
      color: #fff;
      letter-spacing: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .titleBox_title {
      position: absolute;
      left: 200px;
      top: 40px;
      font-size: 24px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #d3d3d3;
      letter-spacing: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .titleBox_userInfo {
      position: absolute;
      right: 10px;
      top: 30px;
      font-size: 24px;
      color: #fff;
      letter-spacing: 1px;
      .user_div {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 60px;

        border-radius: 50%;
        &:hover .user_list {
          display: block;
        }
        .userimg {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          border: 1px solid #ffffff;
        }
        .user_list {
          position: absolute;
          display: none;
          z-index: 29;
          top: 60px;
          width: 110%;
          min-width: 100px;
          padding: 10px 0;
          background-color: #ffffff;
          border: 1px solid #ebeef5;
          border-radius: 4px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          p {
            list-style: none;
            line-height: 36px;
            padding: 0 10px;
            margin: 0;
            font-size: 14px;
            color: #606266;
            cursor: pointer;
            outline: none;
          }
          .user_list_triangle {
            top: -11px;
            left: calc(50% - 3px);
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-bottom: 6px solid #ffffff;
          }
        }
      }
    }
  }
  .contentBox {
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    flex-direction: row;
    &_left {
      width: calc(80% - 10px);
      height: 100%;
      .live_box {
        width: 100%;
        height: 100%;
        position: relative;
        text-align: left;
        &:hover {
          .sliderBox_zhibo {
            display: block;
          }
        }
        &:hover {
          .sliderBox {
            display: block;
          }
        }
        .sliderBox {
          display: none;
          position: absolute;
          bottom: 0px;
          left: 0px;
          //background: transparent;
          width: 100%;
          height: 60px;
          z-index: 1000000;
          background: rgba(0, 16, 27, 0.7);
          .zhiboBottom_box {
            width: 100%;
            height: 60px;
            display: flex;
            flex-direction: column;
          }
          .right_box_bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            .video_yinliang {
              width: 50px;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              margin-right: 15px;
              &:hover {
                .contentSpan {
                  font-size: 12px;
                  color: #ffffff;
                  border: 1px solid #ffffff;
                }
                .user_list_beisu {
                  display: block;
                }
              }
              .slider_box {
                width: 50px;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                .el-slider__button {
                  width: 8px;
                  height: 8px;
                  border: 2px solid rgba(9, 237, 28, 0.92);
                }
                .el-slider__runway {
                  background-color: white;
                }
                .el-slider__bar {
                  background-color: rgba(9, 237, 28, 0.92);
                }
              }
              .contentSpan {
                display: block;
                width: 50px;
                height: 25px;
                text-align: center;
                line-height: 25px;
                font-size: 12px;
                color: #ffffff;
                border-radius: 10px;
                background-color: rgba(0, 0, 0, 0.38);
                cursor: pointer;
              }
              .user_list_beisu {
                position: absolute;
                bottom: 50px;
                background-color: rgba(0, 0, 0, 0.85);
                left: 0px;
                width: 50px;
                height: 200px;
                display: none;
                font-size: 12px;
                text-align: center;
                color: #ffffff;
              }
            }
            .video_beishu {
              width: 50px;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              margin-right: 15px;
              &:hover {
                .contentSpan {
                  font-size: 12px;
                  color: #ffffff;
                  border: 1px solid #ffffff;
                }

                .user_list_beisu {
                  display: block;
                }
              }
              .user_list_beisu {
                position: absolute;
                bottom: 50px;
                background-color: rgba(0, 0, 0, 0.85);
                left: -10px;
                width: 70px;
                display: none;
                font-size: 12px;
                text-align: center;
                color: #ffffff;
                p {
                  width: 100%;
                  height: 35px;

                  display: flex;
                  justify-content: center;
                  align-items: center;
                  span {
                    display: block;
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    cursor: pointer;
                  }
                }

                .isActive {
                  border-radius: 16px;
                  border: 1px solid #fff;
                }
              }
              .contentSpan {
                display: block;
                width: 50px;
                height: 25px;
                text-align: center;
                line-height: 25px;
                font-size: 12px;
                color: #ffffff;
                border-radius: 10px;
                background-color: rgba(0, 0, 0, 0.38);
                cursor: pointer;
              }
            }
            .video_damu {
              width: 50px;
              height: 20px;
              line-height: 20px;
              border-radius: 10px;
              border: 1px solid #ffffff;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              .closeDanMu {
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAA2UlEQVQ4T7WUQQ4BQRBF37ew4goOQOIQFhL3sBEWOAgWxMY9JBYOIeEArsDKQkknM5MejDApvauk+nXXr18lnI+ceRQCzawF9IEu0EgePgM7YCPp+O4zL0AzqwIzYABUCiq4A2tgKukW5+SACWwLdL6UYg/0YugzcAkMv4SlaStJozTIgIlmhw9lFr0Tym+nmsbAOTD+8Xdp+kLSJAQxMHStWRJ4khRckQNegFpJ4FVS/e9A95LdmxJE9bNNENTM/IydAMMc+41eBPVZDrEH3dZXSWNn19w39gP54FwVYX9ggwAAAABJRU5ErkJggg==)
                  no-repeat;
                background-size: 10px;
                width: 10px;
                height: 10px;
              }
              .openDanMu {
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABm0lEQVQ4T62VvytGYRTHP1/FxCSLwaIUyuQfYFA2FIOyWYT8KJI/QKL8CFlsYjBgUwb+AZNClMVgkYmJcnRy3rf7Xu/LfeXWM9znnudzzvc5P64o8phZJdATqx2oD7NH4AI49iXpPX1c6Q0z6wWWgcZizhJ798CMpKOkXR5oZhXAOjAWBlfADnAKPMReA9AFDAOtsefO5yR9+HsSuBEw/zDvUeaMiqhw5yPAClAVtrN5YMg8BBzmxh5dh6Snn2SbWSdwEtA+l69IwE3c2RwwFHKyQkeBTcDvtNmB/cBBRNUG1ALnWaFx95dhP+DAPWAQmJK05hLNrK5M6CSwCuw78BZocg+SrnN3Vg7UzFpC4Z0DX4BqoEbSazIJWaFm5ued8/ojMKv8NLCo5HIiTUv+lpQS/V0yUWZWkJSCsinVHaXkA89AQdn4ZMkV9pikrV+6Ix3pLrCYL+zw7BPGW+8N6JZ0VgY0165frZeouyUfRwGdBrZ/GQ5uuxC9vylpPD1tfCh46G7oT9bx5X088W18JSL9nwGbqrs//wI+AXCb8I0SDp6JAAAAAElFTkSuQmCC)
                  no-repeat;
                background-size: 10px;
                width: 10px;
                height: 10px;
              }
              span {
                font-size: 12px;
                color: #ffffff;
              }
            }
          }
          .videoSwitch {
            width: 50px;
            height: 40px;
            line-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            div {
              position: relative;
              float: right;
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADEUlEQVRoQ+2Z26tOQRiHn1/O53NSlFwLEYm0I8khIYlIlBxSpCQ5ZBc5hJRQkqTkfD4fr1xw4caNv4C48A9QeDW1di3j+749s75Za9tlXa5vZtbzzLvWvO/MJzr5pU7Oz3+BFBE0sxHAaOCdpB8xY3Z4BMxsOXAR6Am8AVpiJDpUIIO/DHTJzfo0SW9Do9BhAnXgvwFjJH35pwXqwP8EVkq6EQrv2lUegQbwqyRdj4GvXCA1fKUCZcBXJlAWfCUCZcKXLtAsvJl1BYYDnyVZrQ+87ipkZvOAc1mnDZKexqwQCeA3AqcBJ/EBmCTpu8/QSOAjMDLr8EnSqFCBBPBjgfdehl5eK0c0EvgjZJKCckYC+HHAK2CYN2GuRnodE4FogQTw4zP4oX+B1pnAZBFIAD8hgx9S82MtUyAB/ETgJTC43ndW7xVuOgIJ4Cdl8IMaLRKlCCSAnwy8AAZ68MeAHfl7yQUSwE/J4Ad48Eck7TKzoEWk0CuUAH4q8Bzo78EfkrTH3StNIAH8NOAZ0M+DPyBpX9u9UgQSwE8HXEniw++X1JoXSi4ArAD8DbjbBgbtpMxsBvAE6OvNfKuk/f4KVIaAg82fHsTAtwCPgT4e6F5JB2stn2UI5J8TAz8TeAT09kB3Szpcb+0vUyAGfhbwsAb8TklHGyWuFAJfAb+oioGfDTwAenmgOyQdb680N7Ogcr5RHvAFYuDnAPez48I863ZJJ9qDz/JA0IYqdEMTAz8XuFsDfpukkyHwMW1Ct5RbJN1rb2Azmw/cAXp4bbdKOtVe/yK/B+2yQgY2swXAbQ/e1TNO/kzIGEXaJBEws4XALaB7DsLBb5Z0tghYaJ+mBcxsEXAT6ObBb5LUdqoRyhPdrikBM1sCuANZH94dw5yPpinQobCAmS0FrmXnNm2P/gWsl3ShAEuhLoUEzGwZcKUG/DpJ7u+iyq5oATNbnH2w+cLOzfxaSZcqI88eVEQgn+LdMC7JrZHkSu3Kr2YFHPxqSVcrJ28iAvkaJShDlykXHYEyYYqM/V+gyKyl7NPpI/Abs5K2QK92rsYAAAAASUVORK5CYII=)
                no-repeat;
              background-size: 24px;
              width: 24px;
              height: 24px;
            }
            div:hover {
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADv0lEQVRoQ+2YWYscVRiG37dm3JdxRQIK4rVod/VENCpjRMQFURGJKKIgRhESBAliproC3VUTURFEBRGRgESNxn0Zs4AiGC8kVX3jL1D0wj9gsOu8UhNHuyrdM7WPA+nLqlOnn+f7zvnOQqzzH9c5P04KVJHBjq8Nhrh85jr89N1mDvP0ueYZsD1tkcEeQaeTPDJzA+bySKypwBK8sFfS1HLUOc1N4U7+mDULayYwFh7805rCFUfn+fv/WmAsPBlReDBw+UFW+Lhd4xmYCE88FDjclwe+cYGq4RsVqAO+MYG64BsRqBO+doGy8Dd9q+ljP+CSI/P4jaTGTfCJVajt63ZFeHPJcgpbw3ku5qkQZeHtvp4wwmuApkH+vGEGncXtPJZmmCjQ6ukXQJce/4C/DlxellWgLPysryuHBgOMrNAWuGXcGrGCgEmkbOBamdaMsvC2r6tkcFjSxaMBo8W50OH3OTKQX6AsfLunqwEcFnRRGnRSACvLQFn4jb5aw+ORv3DcUK1VoCx8x5cdRTgE6IJJ86w2gbLwdk8dQxyCdP5KRaIWgbLwrd3aiL9wENB5CXjyRUg7Rp9VLlAWftbTNZFwUNJMotqAz4cun2v1shWRQpO4LLzt6VoZHBB07ii8BS4ELufjZ7UJlIVvedoE4RtI5yQiT/bDLt3lZ7UIlIWfXdD1wwiLJ8CDvdDlrlGhygUsiw+ccACPj4EZT1L2gm40Q3wN6OzUCrsrdNhLV6DKBUhGiduDHPDtBc0pwleQzkqMedIJuvTHlc/KBVLjNXPkO742mwhfCjozVSp3DrrcPan21yYQZyLzsOnrZglfpOEti88GDl9YaeGqQEB/ILWpygPf7usWAJ9LOiMBanHHwOFLq23Ns27nVzoPJATywHf6utUIn8XXhakJ+0zo8OXV4OP3WQ9UmQ40eeDtvm6T8EkaHuTTgy5fyQKfp02mI+UUse1ol5+u1nGrrzsAfAzptNSk3x52+epq3xd5n+mUlaXjjq87I4OPEvCkLGBb0OXrWfoo0qYSAdvTXcZgP6BT/4UgReKp0OEbRcCyflNaoOXpbgofSjplFN4SngxcLt1q1PkrJWB7ulfCvjQ8hK0Dl2/VCb7cd2GBtqf7YPC+4nub/0JvCDweuny7Cfj4PwoJtDzdT4N30/AW8Fjgck9T8IUEOn3dY4D9oxs7gIYWHg0dvtMkfCGB5BIP/LPIPRI43Ns0fGmBGB7Ew6HD99YCvpDA6B4l6wpdp1yhSVwnUN6+TwrkjVjV7dd9Bv4GWqejT0R8u3gAAAAASUVORK5CYII=)
                no-repeat;
              background-size: 24px;
              width: 24px;
              height: 24px;
            }
          }

          .startOrStop {
            width: 190px;
            height: 40px;
            line-height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            .kongjian_box_times {
              font-size: 14px;
              color: #ffffff;
            }
            .stopBox {
              position: relative;
              float: left;
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACD0lEQVRoQ+2Zv0odQRSHv98jWIek8CHyBHkC8wgW6WITghgUggbypxCLQBAsBBsl3sZGSCBGhFQpQoIgGkFELMR4RVREZeTALsgl3J29987ODtxpd3bn+/bMnNk5KxJvSpyfvkDsCPYjkEQEnHMPgBngSQb8BZiS9Kv2As65R8A3YLAF9haYBcYlHccSKVwDzrllYKgN4IlJAJ8kmVSlzUfgHzDgQfUbGJFk0aqs+Qi4kjSfgReS9kre11H3EAIGcgl8AN5JuuiIzPOmUAL58PtZNJY8eUp3Cy2QA60Dz0Ok3aoETCRI2q1SII9GT9NuDIFc5E82rbpKuzEFcpGu0m4dBLpKu3UR6Djt1k0gF/kODEv6W7Qx1FXAuC1bPZa0006izgLG3ZD0NGWBpqS2X8J1j0DyAklPoWa2iLdTXANfgWeSdlNLo1blGJW0WgSeX6/LIrbjpxUGFiSVOsLGFrCCwRvgo6Qr37d+v18sATszW6HsraTTTsBjTSE7lc0DE5IOugGPIbCSLdDNXoBXKfADeClpo5fgVQhsAWOSGiHAQwocAq+BOUk3IeHt2T5ZyLc2ega8B6YlnYcGLxOBour0tVWmgUlJR1WBlxF4CKz95/+A7ZiLwCufb5ZQYoVTyAZ2zpnEdMsfGtuEfoYC832ul4Dvw2L06wvEeOulPuZiAxaN359CRW8o9PXkI3AH4FDcMXntc9QAAAAASUVORK5CYII=)
                no-repeat;
              background-size: 24px;
              width: 24px;
              height: 24px;
              margin-left: 15px;
            }
            .startBox {
              position: relative;
              float: left;
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAA0ElEQVRoQ+2Zyw0CMRBD7T6gIBqhKBqhILaP4cQFocxKVhQivb1G6/naM1GszT9v7r8IYHUFqcDfV6CqbpIeki5fzh6S7rafSRApfttCVfX64fzH58P2NQwgwj8TQI0ctN1ijP6vqgi/NZ4a6KqT4hMALQQHGpalJIPEjUyjQqgQKoQKscyxjQ5ZwCSeLBJMYiYxk3gyybgPcB8ISUYL0UK00JgF6bLILrTDLhQ9QJyQ0Qj/TAvt/cTUZXD1eVuB1Q529gmgy9DscyowO8Md/hvOMSxAGNSCbAAAAABJRU5ErkJggg==)
                no-repeat;
              background-size: 24px;
              width: 24px;
              height: 24px;
              margin-left: 15px;
            }
          }
          .sliderContent {
            width: 100%;
            height: 20px;
            line-height: 20px;
            //display: flex;
            //align-items: center;
            .el-slider__button {
              width: 8px;
              height: 8px;
              border: 2px solid rgba(9, 237, 28, 0.92);
            }
            .el-slider__runway {
              background-color: rgba(0, 16, 27, 0.7);
            }
            .el-slider__bar {
              background-color: rgba(9, 237, 28, 0.92);
            }
          }
          .sliderContent_kongjian {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .videoQp {
            width: 50px;
            height: 40px;
            line-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            div {
              position: relative;
              float: right;
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAwCAYAAABT9ym6AAABM0lEQVRoQ+2Z0Q3CMAxEzyvwB2IHVmANGIQhGATWYAV2QPSPFYKCQGqjkkQXuSnB/Y5j3ztHSmNBI580ogMmZG5ORh1xzq0BHAFsASxjxYtIkbvOOZeA0wG4ADiIyC1c+zX5W8QVwCKH/gRCPmU8AGxCMTEhJwC7HBF+zYRCfLqziOz7tcWE3FPtNNhIv7X66ToRWeUKGfRsKfFcZ7+tC89QWE/MERNSSn8s3hxJEdCgHtszVY+dEXOEJEC3FpmvWljRRa9a1SOJTcic3HhdWudWEFuPCWHJacWZI1pk2X3NEZacVpw5okWW3dccYclpxbXvSOpHRousvWuxBMwRkkCq1e05iARLh5kjKQI0WjIwVc9fDHqaGb35ie7vD0N9KzcxnibPZLWw9m+/1dCSiZtx5Ak8Sc4xYB6f4gAAAABJRU5ErkJggg==)
                no-repeat;
              background-size: 24px;
              width: 24px;
              height: 24px;
            }
            div:hover {
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABDUlEQVRoQ+2ZQQ7CIBREIR7ApGu9hnoFL+LGHqduvIhXUK+h6yYewGBYmCg0/ZBJaD+ZbsuHP/MmpQFrlD9Wef+GAqYmGBHYta+9M++zc2aV0tz91EAUN8fepaxjrXlaszhcu+Xld3y0+LbtH6nN+4lKCfBreRG3rlmPCkh15DtJSQFDhkUEqhOAOpyS77ExoaFhPyIBCgARkAAjxAj979T1f4VA4sXLoR+x4t0OLEgBU1MgARIAHWCEQAPhchKALQQnIAHQQLicBGALwQnqIyCdAoCGZZdL/fBcKNvSzAIS4LlQZmTC4YyQ+ghJCUAFohcq4j6gToD6Sz7116xSZOb2vr6/0bk5LPWjnsAH/orSMbiaHvkAAAAASUVORK5CYII=)
                no-repeat;
              background-size: 24px;
              width: 24px;
              height: 24px;
            }
          }
        }
        .sliderBox_zhibo {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          height: 60px;
          z-index: 1000000;
          background: rgba(0, 16, 27, 0.7);
          display: none;
          .zhiboBottom_box {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .right_box_bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            .video_yinliang {
              width: 50px;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              margin-right: 15px;
              &:hover {
                .contentSpan {
                  font-size: 12px;
                  color: #ffffff;
                  border: 1px solid #ffffff;
                }
                .user_list_beisu {
                  display: block;
                }
              }
              .slider_box {
                width: 50px;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                .el-slider__button {
                  width: 8px;
                  height: 8px;
                  border: 2px solid rgba(9, 237, 28, 0.92);
                }
                .el-slider__runway {
                  background-color: white;
                }
                .el-slider__bar {
                  background-color: rgba(9, 237, 28, 0.92);
                }
              }
              .contentSpan {
                display: block;
                width: 50px;
                height: 25px;
                text-align: center;
                line-height: 25px;
                font-size: 12px;
                color: #ffffff;
                border-radius: 10px;
                background-color: rgba(0, 0, 0, 0.38);
                cursor: pointer;
              }
              .user_list_beisu {
                position: absolute;
                bottom: 50px;
                background-color: rgba(0, 0, 0, 0.85);
                left: 0px;
                width: 50px;
                height: 200px;
                display: none;
                font-size: 12px;
                text-align: center;
                color: #ffffff;
              }
            }
            .video_damu {
              width: 50px;
              height: 20px;
              line-height: 20px;
              border-radius: 10px;
              border: 1px solid #ffffff;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              .closeDanMu {
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAA2UlEQVQ4T7WUQQ4BQRBF37ew4goOQOIQFhL3sBEWOAgWxMY9JBYOIeEArsDKQkknM5MejDApvauk+nXXr18lnI+ceRQCzawF9IEu0EgePgM7YCPp+O4zL0AzqwIzYABUCiq4A2tgKukW5+SACWwLdL6UYg/0YugzcAkMv4SlaStJozTIgIlmhw9lFr0Tym+nmsbAOTD+8Xdp+kLSJAQxMHStWRJ4khRckQNegFpJ4FVS/e9A95LdmxJE9bNNENTM/IydAMMc+41eBPVZDrEH3dZXSWNn19w39gP54FwVYX9ggwAAAABJRU5ErkJggg==)
                  no-repeat;
                background-size: 10px;
                width: 10px;
                height: 10px;
              }
              .openDanMu {
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABm0lEQVQ4T62VvytGYRTHP1/FxCSLwaIUyuQfYFA2FIOyWYT8KJI/QKL8CFlsYjBgUwb+AZNClMVgkYmJcnRy3rf7Xu/LfeXWM9znnudzzvc5P64o8phZJdATqx2oD7NH4AI49iXpPX1c6Q0z6wWWgcZizhJ798CMpKOkXR5oZhXAOjAWBlfADnAKPMReA9AFDAOtsefO5yR9+HsSuBEw/zDvUeaMiqhw5yPAClAVtrN5YMg8BBzmxh5dh6Snn2SbWSdwEtA+l69IwE3c2RwwFHKyQkeBTcDvtNmB/cBBRNUG1ALnWaFx95dhP+DAPWAQmJK05hLNrK5M6CSwCuw78BZocg+SrnN3Vg7UzFpC4Z0DX4BqoEbSazIJWaFm5ued8/ojMKv8NLCo5HIiTUv+lpQS/V0yUWZWkJSCsinVHaXkA89AQdn4ZMkV9pikrV+6Ix3pLrCYL+zw7BPGW+8N6JZ0VgY0165frZeouyUfRwGdBrZ/GQ5uuxC9vylpPD1tfCh46G7oT9bx5X088W18JSL9nwGbqrs//wI+AXCb8I0SDp6JAAAAAElFTkSuQmCC)
                  no-repeat;
                background-size: 10px;
                width: 10px;
                height: 10px;
              }
              span {
                font-size: 12px;
                color: #ffffff;
              }
            }
          }
          .videoSwitch {
            width: 50px;
            height: 40px;
            line-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            div {
              position: relative;
              float: right;
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADEUlEQVRoQ+2Z26tOQRiHn1/O53NSlFwLEYm0I8khIYlIlBxSpCQ5ZBc5hJRQkqTkfD4fr1xw4caNv4C48A9QeDW1di3j+749s75Za9tlXa5vZtbzzLvWvO/MJzr5pU7Oz3+BFBE0sxHAaOCdpB8xY3Z4BMxsOXAR6Am8AVpiJDpUIIO/DHTJzfo0SW9Do9BhAnXgvwFjJH35pwXqwP8EVkq6EQrv2lUegQbwqyRdj4GvXCA1fKUCZcBXJlAWfCUCZcKXLtAsvJl1BYYDnyVZrQ+87ipkZvOAc1mnDZKexqwQCeA3AqcBJ/EBmCTpu8/QSOAjMDLr8EnSqFCBBPBjgfdehl5eK0c0EvgjZJKCckYC+HHAK2CYN2GuRnodE4FogQTw4zP4oX+B1pnAZBFIAD8hgx9S82MtUyAB/ETgJTC43ndW7xVuOgIJ4Cdl8IMaLRKlCCSAnwy8AAZ68MeAHfl7yQUSwE/J4Ad48Eck7TKzoEWk0CuUAH4q8Bzo78EfkrTH3StNIAH8NOAZ0M+DPyBpX9u9UgQSwE8HXEniw++X1JoXSi4ArAD8DbjbBgbtpMxsBvAE6OvNfKuk/f4KVIaAg82fHsTAtwCPgT4e6F5JB2stn2UI5J8TAz8TeAT09kB3Szpcb+0vUyAGfhbwsAb8TklHGyWuFAJfAb+oioGfDTwAenmgOyQdb680N7Ogcr5RHvAFYuDnAPez48I863ZJJ9qDz/JA0IYqdEMTAz8XuFsDfpukkyHwMW1Ct5RbJN1rb2Azmw/cAXp4bbdKOtVe/yK/B+2yQgY2swXAbQ/e1TNO/kzIGEXaJBEws4XALaB7DsLBb5Z0tghYaJ+mBcxsEXAT6ObBb5LUdqoRyhPdrikBM1sCuANZH94dw5yPpinQobCAmS0FrmXnNm2P/gWsl3ShAEuhLoUEzGwZcKUG/DpJ7u+iyq5oATNbnH2w+cLOzfxaSZcqI88eVEQgn+LdMC7JrZHkSu3Kr2YFHPxqSVcrJ28iAvkaJShDlykXHYEyYYqM/V+gyKyl7NPpI/Abs5K2QK92rsYAAAAASUVORK5CYII=)
                no-repeat;
              background-size: 24px;
              width: 24px;
              height: 24px;
            }
            div:hover {
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADv0lEQVRoQ+2YWYscVRiG37dm3JdxRQIK4rVod/VENCpjRMQFURGJKKIgRhESBAliproC3VUTURFEBRGRgESNxn0Zs4AiGC8kVX3jL1D0wj9gsOu8UhNHuyrdM7WPA+nLqlOnn+f7zvnOQqzzH9c5P04KVJHBjq8Nhrh85jr89N1mDvP0ueYZsD1tkcEeQaeTPDJzA+bySKypwBK8sFfS1HLUOc1N4U7+mDULayYwFh7805rCFUfn+fv/WmAsPBlReDBw+UFW+Lhd4xmYCE88FDjclwe+cYGq4RsVqAO+MYG64BsRqBO+doGy8Dd9q+ljP+CSI/P4jaTGTfCJVajt63ZFeHPJcgpbw3ku5qkQZeHtvp4wwmuApkH+vGEGncXtPJZmmCjQ6ukXQJce/4C/DlxellWgLPysryuHBgOMrNAWuGXcGrGCgEmkbOBamdaMsvC2r6tkcFjSxaMBo8W50OH3OTKQX6AsfLunqwEcFnRRGnRSACvLQFn4jb5aw+ORv3DcUK1VoCx8x5cdRTgE6IJJ86w2gbLwdk8dQxyCdP5KRaIWgbLwrd3aiL9wENB5CXjyRUg7Rp9VLlAWftbTNZFwUNJMotqAz4cun2v1shWRQpO4LLzt6VoZHBB07ii8BS4ELufjZ7UJlIVvedoE4RtI5yQiT/bDLt3lZ7UIlIWfXdD1wwiLJ8CDvdDlrlGhygUsiw+ccACPj4EZT1L2gm40Q3wN6OzUCrsrdNhLV6DKBUhGiduDHPDtBc0pwleQzkqMedIJuvTHlc/KBVLjNXPkO742mwhfCjozVSp3DrrcPan21yYQZyLzsOnrZglfpOEti88GDl9YaeGqQEB/ILWpygPf7usWAJ9LOiMBanHHwOFLq23Ns27nVzoPJATywHf6utUIn8XXhakJ+0zo8OXV4OP3WQ9UmQ40eeDtvm6T8EkaHuTTgy5fyQKfp02mI+UUse1ol5+u1nGrrzsAfAzptNSk3x52+epq3xd5n+mUlaXjjq87I4OPEvCkLGBb0OXrWfoo0qYSAdvTXcZgP6BT/4UgReKp0OEbRcCyflNaoOXpbgofSjplFN4SngxcLt1q1PkrJWB7ulfCvjQ8hK0Dl2/VCb7cd2GBtqf7YPC+4nub/0JvCDweuny7Cfj4PwoJtDzdT4N30/AW8Fjgck9T8IUEOn3dY4D9oxs7gIYWHg0dvtMkfCGB5BIP/LPIPRI43Ns0fGmBGB7Ew6HD99YCvpDA6B4l6wpdp1yhSVwnUN6+TwrkjVjV7dd9Bv4GWqejT0R8u3gAAAAASUVORK5CYII=)
                no-repeat;
              background-size: 24px;
              width: 24px;
              height: 24px;
            }
          }
          .videoQp {
            width: 50px;
            height: 40px;
            line-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            div {
              position: relative;
              float: right;
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAwCAYAAABT9ym6AAABM0lEQVRoQ+2Z0Q3CMAxEzyvwB2IHVmANGIQhGATWYAV2QPSPFYKCQGqjkkQXuSnB/Y5j3ztHSmNBI580ogMmZG5ORh1xzq0BHAFsASxjxYtIkbvOOZeA0wG4ADiIyC1c+zX5W8QVwCKH/gRCPmU8AGxCMTEhJwC7HBF+zYRCfLqziOz7tcWE3FPtNNhIv7X66ToRWeUKGfRsKfFcZ7+tC89QWE/MERNSSn8s3hxJEdCgHtszVY+dEXOEJEC3FpmvWljRRa9a1SOJTcic3HhdWudWEFuPCWHJacWZI1pk2X3NEZacVpw5okWW3dccYclpxbXvSOpHRousvWuxBMwRkkCq1e05iARLh5kjKQI0WjIwVc9fDHqaGb35ie7vD0N9KzcxnibPZLWw9m+/1dCSiZtx5Ak8Sc4xYB6f4gAAAABJRU5ErkJggg==)
                no-repeat;
              background-size: 24px;
              width: 24px;
              height: 24px;
            }
            div:hover {
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABDUlEQVRoQ+2ZQQ7CIBREIR7ApGu9hnoFL+LGHqduvIhXUK+h6yYewGBYmCg0/ZBJaD+ZbsuHP/MmpQFrlD9Wef+GAqYmGBHYta+9M++zc2aV0tz91EAUN8fepaxjrXlaszhcu+Xld3y0+LbtH6nN+4lKCfBreRG3rlmPCkh15DtJSQFDhkUEqhOAOpyS77ExoaFhPyIBCgARkAAjxAj979T1f4VA4sXLoR+x4t0OLEgBU1MgARIAHWCEQAPhchKALQQnIAHQQLicBGALwQnqIyCdAoCGZZdL/fBcKNvSzAIS4LlQZmTC4YyQ+ghJCUAFohcq4j6gToD6Sz7116xSZOb2vr6/0bk5LPWjnsAH/orSMbiaHvkAAAAASUVORK5CYII=)
                no-repeat;
              background-size: 24px;
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
    &_right {
      width: 20%;
      height: 100%;
      margin-left: 10px;
      .live_box_tearher {
        width: 100%;
        height: 30%;
      }
      .chat_room {
        width: 100%;
        height: 70%;
      }
    }
  }
  .bottomBox {
    width: 100%;
    height: 60px;
  }
}
.c-menu-panel__tabs__item.c-menu-panel__tabs__item--current {
  color: red;
}
.account {
  position: relative;
  margin-right: 20px;
  &:hover {
    .account-box {
      display: block;
    }
  }
  .account-box {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 38px;
    background: #fff;
    padding: 10px;
    display: none;
    img {
      width: 100px;
      height: 100px;
    }
  }
}
.dow-app {
  position: relative;
  font-size: 14px;
  &:hover {
    .dow-box {
      display: block;
    }
  }
  .dow-box {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 38px;
    background: #fff;
    padding: 10px;
    display: none;
    z-index: 100000;
    img {
      width: 100px;
      height: 100px;
    }
  }
}

.iframes-box-ul {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  ul {
    color: #fff;
    list-style: none;
    display: flex;
    li {
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
.drawer-bg {
  text-align: left;
  color: #fff;
  .el-drawer.rtl {
    background: #2d2d2d;
    .el-drawer__header {
      color: #fff;
      text-align: center;
    }
  }
  .ul {
    margin-top: 16px;
    li {
      list-style: none;
      margin: 0 24px;
      margin-bottom: 26px;
      display: flex;
      justify-content: space-between;
      p {
        word-break: break-all;
      }
      img {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
      .dow {
        margin-right: 0;
        margin-left: 8px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
  .questions {
    margin: 0 24px;
    .el-textarea {
      margin-top: 16px;
      textarea {
        background-color: #1d1d1d !important;
        border: 0 !important;
        color: #fff;
      }
      .el-input__count {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #7d7d7d;
        background-color: #1d1d1d !important;
      }
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 80px;
      height: 80px;
    }
    .upload-uploader {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;
    }
    .el-upload--picture-card {
      background-color: transparent;
      width: 80px !important;
      height: 80px !important;
      line-height: 80px;
      margin: 0 8px 8px 0;
    }
    .el-menu-item {
      padding-left: 20px !important;
      padding-right: 20px !important;
      &.on {
        background-color: #1d1d1d;
      }
    }
  }
}
</style>